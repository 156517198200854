import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { twMerge } from "tailwind-merge";

interface TimelineElementProps {
    title?: string;
    content?: string;
    number?: string;
    bulletColor?: string;
    borderColor?: string;
    hasMonths?: boolean;
    monthsColor?: string;
    months?: string;
    iconPlacement?: string;
}

export default function TimelineElement({
    title,
    content,
    number = "1",
    bulletColor = "#000",
    borderColor = "#F1F1F1",
    hasMonths = false,
    monthsColor,
    months,
    iconPlacement = "-left-5 -top-1 lg:-left-[9px] md:-left-[20px] md:-top-[6px] lg:top-[5.2px]",
}: TimelineElementProps) {
    return (
        <>
            <VerticalTimelineElement
                visible={true}
                textClassName="text-black font-bold"
                contentStyle={{
                    background: "#fff",
                    color: "#fff",
                    border: "solid",
                    borderColor: "#F1F1F1",
                    fontWeight: 900,
                }}
                contentArrowStyle={{
                    borderRight: "12px solid #F1F1F1",
                }}
                date=""
                iconStyle={{
                    background: `${bulletColor}`,
                    boxShadow: `0 0 0 4px ${borderColor}, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05)`,
                }}
                icon={
                    <div
                        className={twMerge(
                            "w-20 text-center  text-black absolute text-xl3 font-bold",
                            iconPlacement,
                        )}
                    >
                        {number}
                    </div>
                }
            >
                {hasMonths ? (
                    <div className="lg:flex gap-5">
                        <div className="flex flex-col justify-start items-start">
                            <div
                                className={twMerge(
                                    "text-center text-[80px] font-bold font-['Avenir Next Condensed'] leading-[88px]",
                                    monthsColor,
                                )}
                            >
                                {months}
                            </div>
                            <div className="text-center text-black text-2xl font-bold  leading-loose">
                                Months
                            </div>
                        </div>
                        <div>
                            <h3 className="text-black  text-xl lg:text-2xl font-bold  leading-loose">
                                {title}
                            </h3>
                            <p className="text-black text-xl1 font-normal  lg:leading-8.5 leading-7">
                                {content}
                            </p>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h3 className="font-bold  text-black text-[24px]">{title}</h3>
                        <p className="text-black text-[17px] font-normal  lg:leading-8.5 leading-7">
                            {content}
                        </p>
                    </div>
                )}
            </VerticalTimelineElement>
        </>
    );
}
