"use client";
import SliderNoArrows from "@/components/Carousel/SliderNoArrows";
import { shuffle } from "@/utils";
import Testimonial from "@/components/Text/Testimonial";
import { SwiperSlide } from "swiper/react";
import type { Meta } from "@storybook/react";
import { twMerge } from "tailwind-merge";
import type { TestimonialsType } from "@/types";

interface TestimonialSlider {
    paginationColor: string;
    element: Meta;
    backgroundColor: string;
    data: TestimonialsType[];
}
export default function TestimonialSliders({
    paginationColor,
    element,
    backgroundColor,
    data,
}: TestimonialSlider) {
    return (
        <>
            <div className={twMerge(" flex justify-center", backgroundColor)}>
                <div className="container w-full  px-3   flex-col justify-center items-center gap-6 inline-flex">
                    <SliderNoArrows
                        paginationColor={paginationColor}
                        contentEl={
                            <div className="flex justify-center items-center">
                                {shuffle(data)?.map((testimonial) => {
                                    return (
                                        <SwiperSlide key={testimonial.testimonial}>
                                            <Testimonial
                                                backgroundColor={
                                                    element?.args?.backgroundColor
                                                }
                                                differentTextColor={
                                                    element?.args?.differentTextColor
                                                }
                                                hasImage={testimonial.hasImage}
                                                text={testimonial?.testimonial}
                                                icon={element.args?.icon}
                                                textColor={element?.args?.textColor}
                                                name={testimonial?.name}
                                                hasDescription={false}
                                                wordsInDifferentColor={[""]}
                                                hasReadMore={true}
                                            />
                                        </SwiperSlide>
                                    );
                                })}
                            </div>
                        }
                    />
                </div>
            </div>
        </>
    );
}
