"use client";
import type { TimelineEl } from "@/types";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import TimelineElement from "./TimelineElement";

interface TimelineProps {
    lineColor?: string;
    iconPlacement?: string;
    content: {
        title: string;
        content: string;
        number: string;
        bulletColor: string;
        borderColor: string;
    }[];
    layout?: "1-column-left" | "1-column-right" | "2-columns";
}

export default function Timeline({
    lineColor = "#F1F1F1",
    content,
    layout = "2-columns",
    iconPlacement,
}: TimelineProps) {
    return (
        <>
            <VerticalTimeline
                className=""
                lineColor={lineColor}
                animate={true}
                layout={layout}
            >
                {content.map((element: TimelineEl) => (
                    <TimelineElement
                        key={element.title}
                        bulletColor={element.bulletColor}
                        content={element.content}
                        number={element.number}
                        title={element.title}
                        borderColor={element.borderColor}
                        hasMonths={element.hasMonths}
                        months={element.months}
                        monthsColor={element.monthsColor}
                        iconPlacement={iconPlacement}
                    />
                ))}
            </VerticalTimeline>
        </>
    );
}
