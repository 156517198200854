"use client";

import SliderNoArrows from "./SliderNoArrows";
import videoTestimonials from "../../../public/content/videoTestimonialsENG.json";
import { SwiperSlide } from "swiper/react";
import PlayerInPage from "../Player/PlayerInPage";
import { testimonialImg } from "@/utils";

interface VideoTestimonial {
    paginationColor: string;
}

export default function VideoTestimonialSlider({ paginationColor }: VideoTestimonial) {
    return (
        <>
            <div className="lg:block md:block hidden">
                <SliderNoArrows
                    slidesPerView={1.5}
                    paginationColor={paginationColor}
                    centered={false}
                    contentEl={
                        <div>
                            {videoTestimonials.videoTestimonials.map((testemonial) => (
                                <SwiperSlide
                                    className="lg:pb-10 pb-2"
                                    key={testemonial.id}
                                >
                                    <PlayerInPage
                                        videoId={testemonial.id}
                                        thumbnail={
                                            testimonialImg.find(
                                                (img) =>
                                                    img.name === `${testemonial.name}`,
                                            )?.url
                                        }
                                    />
                                </SwiperSlide>
                            ))}
                        </div>
                    }
                />
            </div>
            <div className="lg:hidden md:hidden flex justify-center">
                <SliderNoArrows
                    slidesPerView={1}
                    paginationColor={paginationColor}
                    centered={false}
                    contentEl={
                        <div>
                            <>
                                {videoTestimonials.videoTestimonials.map(
                                    (testemonial) => (
                                        <SwiperSlide
                                            className="lg:pb-10 pb-6"
                                            key={testemonial.id}
                                        >
                                            <PlayerInPage
                                                videoId={testemonial.id}
                                                thumbnail={
                                                    testimonialImg.find(
                                                        (img) =>
                                                            img.name ===
                                                            `${testemonial.name}`,
                                                    )?.url
                                                }
                                            />
                                        </SwiperSlide>
                                    ),
                                )}
                            </>
                        </div>
                    }
                />
            </div>
        </>
    );
}
